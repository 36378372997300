import React from "react"
import Layout from "../../components/Layout/layout"
import { Container } from "@material-ui/core"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import ImageCard from '../../components/PageInfoSection/components/ImageCard'
import image from '../../assets/images/ui-ux.jpeg'
import Squares from '../../components/Squares/Squares'
import { graphql, useStaticQuery } from 'gatsby'

const WebDevelopmentPage = () => {
  const { develop } = useStaticQuery(graphql`
  query develop {
    develop: file(relativePath: {eq: "ui-ux.jpeg"}) {
      childImageSharp {
        fluid {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <Squares>
        <Container>
          <PageInfoSection
            title={`web design &`}
            blueTitle="development"
            text="We pride ourselves on having the most innovative software and cutting edge design. Our websites feature unparalleled speed, user-friendly configuration, and modern design for owners and users alike."
          >
            <ImageCard image={develop.childImageSharp.fluid} text='Web Design and Development' />
          </PageInfoSection>
        </Container>
      </Squares>

    </Layout>
  )
}

export default WebDevelopmentPage
